import { HierarcicalObjectReference } from "@novorender/data-js-api";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Tree } from "components/objectTree/types";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { AsyncState, AsyncStatus } from "types/misc";

import { ModelTreeFilter, ModelTreeNodeData } from "../types";
import { emptyModelTree, filterTypeToStr, loadFilesWithType } from "../utils";

export function useFileTypeObjects() {
    const {
        state: { db },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();
    const fileTypeObjectsCache = useRef(new Map<string, HierarcicalObjectReference[]>());

    const [fileTypeObjects, setFileTypeObjects] = useState<
        AsyncState<{ type: ModelTreeFilter; objects: HierarcicalObjectReference[] }[]>
    >({
        status: AsyncStatus.Initial,
    });

    const loadFileTypeObjects = useCallback(
        async function loadFileTypeObjects({
            fileTypes,
            setTree,
            abortSignal,
        }: {
            fileTypes: ModelTreeFilter[];
            setTree: Dispatch<SetStateAction<Tree<ModelTreeNodeData>>>;
            abortSignal: AbortSignal;
        }) {
            if (fileTypes.length === 0) {
                setFileTypeObjects({ status: AsyncStatus.Success, data: [] });
                setTree(emptyModelTree);
                return;
            }

            const toLoad = fileTypes.filter((pt) => !fileTypeObjectsCache.current.has(filterTypeToStr(pt)));
            const alreadyLoaded = fileTypes
                .filter((pt) => fileTypeObjectsCache.current.has(filterTypeToStr(pt)))
                .map((type) => ({
                    type,
                    objects: fileTypeObjectsCache.current.get(filterTypeToStr(type))!,
                }));

            if (toLoad.length === 0) {
                setFileTypeObjects({
                    status: AsyncStatus.Success,
                    data: alreadyLoaded,
                });
                setTree(emptyModelTree);
                return;
            }

            const filterTypeStrToValue = new Map(fileTypes.map((ft) => [filterTypeToStr(ft), ft]));

            setFileTypeObjects({ status: AsyncStatus.Loading });
            try {
                const objectsByFileType = await loadFilesWithType({
                    db,
                    abortSignal,
                    fileTypes: toLoad,
                });

                objectsByFileType.forEach((objects, type) => {
                    fileTypeObjectsCache.current.set(type, objects);
                });

                setFileTypeObjects({
                    status: AsyncStatus.Success,
                    data: [
                        ...alreadyLoaded,
                        ...objectsByFileType.entries().map(([type, objects]) => ({
                            type: filterTypeStrToValue.get(type)!,
                            objects,
                        })),
                    ],
                });
                setTree(emptyModelTree);
            } catch (ex) {
                console.warn(ex);
                if ((ex as { name: string }).name === "AbortError") {
                    return;
                }
                setFileTypeObjects({ status: AsyncStatus.Error, msg: t("errorLoadingFileExtensionInfo") });
            }
        },
        [t, db],
    );

    return { fileTypeObjects, loadFileTypeObjects };
}
