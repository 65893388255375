import { HierarcicalObjectReference } from "@novorender/data-js-api";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useSceneId } from "hooks/useSceneId";
import { selectIsOnline } from "slices/explorer";
import { AsyncStatus } from "types/misc";
import { getManualCache } from "utils/manualCache";
import { getObjectNameFromPath, getParentPath } from "utils/objectData";
import { searchByPatterns } from "utils/search";

import { selectAlignmentIds } from "../selectors";
import { alignmentActions } from "../slice";
import { AlignmentId } from "../types";

export function useLoadAlignments({ skip }: { skip?: boolean } = {}) {
    const {
        state: { db },
    } = useExplorerGlobals();
    const { t } = useTranslation();
    const alignments = useAppSelector(selectAlignmentIds);
    const dispatch = useAppDispatch();
    const projectId = useSceneId();
    const isOnline = useAppSelector(selectIsOnline);
    const isOnlineRef = useRef(isOnline);
    useEffect(() => {
        isOnlineRef.current = isOnline;
    });

    useEffect(() => {
        if (!skip && alignments.status === AsyncStatus.Initial) {
            getAlignments();
        }

        async function getAlignments() {
            if (!db) {
                return;
            }

            dispatch(alignmentActions.setAlignmentIds({ status: AsyncStatus.Loading }));

            const cacheKey = `/derived/projects/${projectId}/alignments`;

            try {
                let alignmentIds = [] as AlignmentId[];

                const cache = await getManualCache();

                const loadFromCache = async () => {
                    const resp = await cache.match(cacheKey);
                    if (resp) {
                        alignmentIds = await resp.json();
                    } else {
                        throw new Error("No cached value for alignments");
                    }
                };

                if (!isOnlineRef.current) {
                    await loadFromCache();
                } else {
                    try {
                        const refsWithPathId: HierarcicalObjectReference[] = [];
                        await searchByPatterns({
                            db,
                            searchPatterns: [{ property: "Novorender/PathId" }],
                            full: true,
                            callback: (refs) => {
                                refsWithPathId.push(...refs);
                            },
                        });

                        alignmentIds = await Promise.all(
                            refsWithPathId.map(async (ref) => {
                                const meta = await ref.loadMetaData();
                                return {
                                    id: ref.id,
                                    name: getObjectNameFromPath(getParentPath(ref.path)),
                                    brepId: meta.properties.find((p) => p[0] === "Novorender/PathId")![1],
                                };
                            }),
                        );

                        if (alignmentIds.length == 0) {
                            //Legacy
                            await searchByPatterns({
                                db,
                                searchPatterns: [
                                    {
                                        property: "Novorender/Path",
                                        value: "true",
                                        exact: true,
                                    },
                                ],
                                callback: (refs) =>
                                    (alignmentIds = alignmentIds.concat(
                                        refs.map(({ path, id }) => ({
                                            id,
                                            name: getObjectNameFromPath(getParentPath(path)),
                                        })),
                                    )),
                            });
                        }

                        cache.put(cacheKey, Response.json(alignmentIds));
                    } catch (e) {
                        console.warn(e);
                        await loadFromCache();
                    }
                }

                alignmentIds.sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "accent" }));
                dispatch(alignmentActions.setAlignmentIds({ status: AsyncStatus.Success, data: alignmentIds }));
            } catch (e) {
                console.warn(e);
                dispatch(
                    alignmentActions.setAlignmentIds({
                        status: AsyncStatus.Error,
                        msg: t("loadPathToFollowListError"),
                    }),
                );
            }
        }
    }, [db, alignments, dispatch, skip, projectId, t]);
}
